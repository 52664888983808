@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
.App{
    background: linear-gradient(180deg, #112946 7.79%, rgba(17, 41, 70, 0.96) 68.29%, rgba(17, 41, 70, 0.93) 99.22%);
}
.NewScV_wrapper{
    width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    display: flex;

}
.NewScV_wrap{
    width: 100%;
    height: 100%;
    display: grid;

    position: absolute;
    background:  url(../../static/media/img.fb203b42.png) no-repeat 80% 15%;
    background-size: 80%;
    grid-template-rows: 1fr;
    grid-template-columns: 55px 1fr;
    max-height: 100vh;
    overflow: hidden;
}
.NewScV_header{

}
.NavMenu_wrap{
    grid-column: 1/ span 1;
    grid-row: 1/ span 3;

}
.BodyBlock_wrap{
    display: flex;
    grid-row: 1/span 1;
    grid-column: 2/ span 1;
    width: 100%;
    height: 100%;
}


.NewScV_footer {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: -webkit-max-content;
    grid-template-rows: max-content;
    position: absolute;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    bottom: 0;
    left: 0;
    grid-column: 1/ span 2;
}
.FooterBlock_wrapper {
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    margin-bottom: -40px;
    display: flex;
    flex-direction: column;
    transition-property: all;
    transition-duration: .5s;
    transition-delay: 0s;
}
.FooterBlock_btn{
    margin: 0 auto;
    cursor: pointer;
    z-index: 1005;
}
.FooterBlock_wrap{
   display: grid;
    grid-template-columns: -webkit-max-content -webkit-max-content -webkit-max-content;
    grid-template-columns: max-content max-content max-content;
    grid-template-rows: -webkit-max-content;
    grid-template-rows: max-content;
    grid-gap: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    background: #024280;
    /*box-shadow: 2px 4px 4px rgba(0, 0, 0, .25);*/
    box-shadow: -2px -4px 4px rgba(0, 0, 0, .25);
    padding: 10px 0;
    z-index: 1000;
    margin-bottom: -1px;
}
.FooterBlock_wrapper svg{
    margin-bottom: -1px;
}
.NewScV_footer.active{
    grid-template-rows: 1fr  -webkit-max-content;
    grid-template-rows: 1fr  max-content;
    height: 100vh;
}
.NewScV_footer .click_area_nav{
    background:inherit;

    width: 100%;
    height: calc(100% + 20px);

    display: block;
}
.FooterBlock_wrapper.active {
    margin-bottom: 0;
    transition-delay: 0s;
}
.FooterBlock_wrap> div{
    cursor: pointer;
}

.HeaderBlock_wrapper{
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
margin-top: -65px;


    display: flex;
    flex-direction: column;

    transition-property: all;
    transition-duration: .5s;
    transition-delay: 0s;

}
.HeaderBlock_wrapper.active{
    margin-top: 0;

    transition-delay: 0s;
}
.NewScV_header{
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: -webkit-max-content;
    grid-template-rows: max-content;
    position: absolute;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    top: 0;
    left: 0;

}
.NewScV_header.active{
    grid-template-rows: -webkit-max-content 1fr;
    grid-template-rows: max-content 1fr;
    height: 100vh;
}

.NewScV_header .click_area_nav{
    width: 100%;
    height: calc(100% + 20px);
    background-color: inherit;
    display: block;
    margin-top: -20px;

    z-index: 999;
}

.HeaderBlock_wrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    background: #024280;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 10px 0;
    z-index: 99999;
    margin-bottom: -1px;
}
.HeaderBlock_item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: -webkit-max-content;
    width: max-content;
    height: -webkit-max-content;
    height: max-content;
    text-align: left;
    grid-gap: 10px;
    text-decoration: none;
    color: white;
}
.HeaderBlock_item svg{
    height: 3.8vh;

}
.HeaderBlock_item  span{
    line-height: 16px;
}
.cifra_logo_text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: -webkit-max-content;
    width: max-content;
    height: -webkit-max-content;
    height: max-content;

}
.cifra_logo_text > span:nth-child(1){
    font-size: 0.8em;
}
.cifra_logo_text > span:nth-child(2){
    font-size: 1em;
    font-weight: 500;
}
.cifra_logo_text > span:nth-child(3){
    font-size: 0.8em;
}
.HeaderBlock_btn{
    margin: 0 auto;
    cursor: pointer;
    z-index: 99999;
}



/* Box sizing rules */
* {
    font-family: 'Roboto', sans-serif !important;


}
.BodyBlock_wrap{
    padding: 10px 10px 0 10px;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 60px 1fr;

}
.BodyBlock_wrap .titleBlockIframe{
    font-size: clamp(18px, 5vw, 26px);
font-weight: 400;
    color: white;
    display: flex;
    align-items: center;
    padding: 10px;
    letter-spacing: 2px;
    width: 100%;
    grid-gap: 10px;
}
.BodyBlock_wrap iframe{

    background: inherit;
    width: 100%;
    height: 100%;
    border: none;


}
.titleBlockIframe > span{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 10px;
    align-self: center;
    height: -webkit-max-content;
    height: max-content;
}
.titleBlockIframe > span svg{
    height: 100%;
    display: flex;
}
.titleBlockIframe > span:last-child svg{
    display: none;
}
.errorT {
    width: 100%;
    height: 100%;
    background-image: url(../../static/media/404.3150af67.svg);
    background-repeat: no-repeat;
    background-size: 100vh;
    background-position: center;
}


.card_lineTop_blue{
    border-top: 5px solid #0189EC;
}
.card_lineTop_liteBlue{
    border-top: 5px solid #00B7FF;
}
.card_lineTop_green{
    border-top: 5px solid #00EED9;
}
.bg_color_dark_blue{
    background: #162F51;
}
.card_lineTop_orange{
    border-top: 5px solid #F3A304;
}
.card_lineTop_red{
    border-top: 5px solid #7A2525;

}


.card_lineleft_green{
    border-left: 5px solid #00EED9;

}
.card_lineleft_orange{
    border-left: 5px solid #F3A304;

}
.card_lineleft_blue{
    border-left: 5px solid #0189EC;

}
.chart_block_def{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 5px;
}
.chart_block_def canvas{
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    max-width: 100%;
    width: 100%!important;
    height: 100%!important;
    /*    position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;*/
}
.vertical_line_border{
    width: 2px;
    height: 90%;
    background: #C4C4C4;
    display: flex;
    max-height: 80%;
    align-self: center;
}
.progress_orange_color{
    background: #F3A304;
}
.progress_red_color{
    background: #7A2525;
}

.source_information{
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    padding: 5px 5px 5px 10px;
    color: #ffffff80;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: clamp(10px, 5vw, 12px);
    text-align: left;

}

.icon_navMenu svg,
.MainPage_NavBar svg {
    width: 25px;
    height: 25px;
}

.MainPage_NavBar svg {
    margin-right: -3px;

}

.NavBar_wrapper {
    grid-row: 1/ span 3;


}

.NavBar_wrap {
    background: #142b4b;
    height: 100%;
    padding: 12px 0;
    box-shadow: 2px 4px 4px rgba(0, 0, 0 , .25)
}

.NavBar_tree.ant-menu {
    background: inherit;
    color: white;
    width: 100%;
    overflow: auto;
    max-height: 95%;
}
.NavBar_tree .ant-menu-submenu-title{
display: flex;
    align-items: center;
}
.NavBar_item  .ant-menu .ant-menu-submenu .ant-menu-submenu-title{
    display: flex;
    align-items: center;
}
.NavBar_tree .ant-menu-item{
    display: flex;
    align-items: center;
}
.dark-theme svg,
.darkTheme svg {
    fill: white;
}

.NavBar_wrapper.collapse {
    width: 100%;
}

.NavMenu_logo_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.NavMenu_logo_item.active {
    width: 150px;
    transition: .1s ease-out;
    transition-delay: .1s;
}

.NavMenu_logo_item.noActive {
    width: 0;
    transition: .1s ease-out;
    transition-delay: 0.1s;
}

.NavMenu_logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    grid-gap: 10px;
    height: 50px;
}

.NavMenu_logo_item svg {
    padding-top: 10px;
    width: 100%;

}

.NavMenu_btn svg {
    width: 25px;
    height: 25px;
}

.darkTheme.ant-menu.ant-menu-dark,
.darkTheme.ant-menu-dark .ant-menu-sub,
.darkTheme.ant-menu.ant-menu-dark .ant-menu-sub {
    background: #142b4b;
    color: white;
}

.NavBar_tree.dark.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: #142b4b;
    color: white;

}

.icon_navMenu svg,
.NavBar_item .ant-menu-item {
    display: flex;
    align-items: center;
    align-self: center;
}

.icon_navMenu.ant-menu-item-icon {
    display: flex;
    align-items: center;
}

.NavBar_item.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,
.NavBar_tree.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: hsla(0, 0%, 100%, .18823529411764706);
    color: #fff;
    display: flex;
    align-items: center;
}

.NavBar_wrapper.noCollapse {
    display: grid;
    grid-template-columns: -webkit-max-content 1fr;
    grid-template-columns: max-content 1fr;
    grid-template-rows: 100%;
    width: 100%;
    position: absolute;
    height: 100%;
}


.NavMenu_btn.ant-btn-primary {
    background: inherit;
    border: none;
    width: -webkit-max-content;
    width: max-content;
    box-shadow: none;
}

.navigation_bar  .ant-menu-inline .ant-menu-item.double_ant{
    padding-left: 48px;

}

* {
    font-family: 'Roboto', sans-serif !important;


}

.App {
    text-align: center;
    letter-spacing: .8px;
    font-weight: 300;
    width: 100%;
    height: 100%;
    overflow: hidden;
    scrollbar-color: transparent; /* «цвет ползунка» «цвет полосы скроллбара» */
    scrollbar-width: thin;


}

body {
    background: inherit;
    /* background-color: #282c34;*/

}

#root {
    width: 100vw;
    height: 100vh;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;

}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5);
}

::-webkit-scrollbar-button {
    width: 0;
    opacity: 0;
}

::-webkit-scrollbar-corner {
    opacity: 0;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.5);
}

::-webkit-scrollbar-button {
    width: 0;
    opacity: 0;
}

::-webkit-scrollbar-corner {
    opacity: 0;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: inherit;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.bodyTest {
    /*  background-image: url(/static/media/bg-pattern.8cfe4242.svg), radial-gradient(farthest-side ellipse at 10% 0, #414e60 20%, #94a3b1);*/

    letter-spacing: 0.5px;

    font-family: 'Helvetica Neue', Arial, sans-serif;
    font-size: 14px;

    color: #ffffff;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
    margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
    list-style: none;
}

/* Set core root defaults */
html:focus-within {
    scroll-behavior: smooth;
}

/* Set core body defaults */
body {
    min-height: 100vh;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
    -webkit-text-decoration-skip: ink;
            text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
    max-width: 100%;
    display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}

::-webkit-scrollbar { width: 0; }
.noCopy {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}

@media (prefers-reduced-motion: reduce) {
    html:focus-within {
        scroll-behavior: auto;
    }
    *,
    *::before,
    *::after {
        -webkit-animation-duration: 0.01ms !important;
                animation-duration: 0.01ms !important;
        -webkit-animation-iteration-count: 1 !important;
                animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
}

